import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Label, FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import ConsumerStatusTopicsTable from './ConsumerStatusTopicsTable/ConsumerStatusTopicsTable';
import useP2PConsumerApi from '../../../../hooks/useP2PConsumerApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildConsumerTopicsData from './ConsumerStatusTopics.utils';

const ConsumerStatusTopics = ({ formData, handleInputChange }) => {
  const { getConsumerTopics, consumerTopicsData, errorGettingConsumerTopics, gettingConsumerTopics } =
    useP2PConsumerApi();
  const tableRowsData = consumerTopicsData ? buildConsumerTopicsData(consumerTopicsData?.topics) : null;

  const getUpdatedP2P = useCallback(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getConsumerTopics({
      onlyWips: formData.onlyWips,
      pending: formData.pending,
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${result.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getConsumerTopics]);

  useEffect(() => {
    getUpdatedP2P();

    const interval = setInterval(() => {
      getUpdatedP2P();
    }, 5000);

    return () => clearInterval(interval);
  }, [getUpdatedP2P]);

  return (
    <>
      <Helmet>
        <title>AM: Consumer Status: Topics</title>
      </Helmet>
      {errorGettingConsumerTopics?.status === 404 && (
        <>
          <p className="mt-3">There are no topics.</p>
          <FormGroup>
            <FormGroup switch>
              <Input
                type="switch"
                id="onlyWips"
                name="onlyWips"
                checked={formData.onlyWips}
                onChange={handleInputChange}
              />
              <Label check for="showWipsOnlySwitch">
                Show Only WIPs
              </Label>
            </FormGroup>
          </FormGroup>
        </>
      )}
      {errorGettingConsumerTopics?.status !== 404 && errorGettingConsumerTopics && (
        <p className="mt-3">There was an issue loading the consumer topics.</p>
      )}
      {(gettingConsumerTopics || !consumerTopicsData) && !errorGettingConsumerTopics && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingConsumerTopics && !errorGettingConsumerTopics && (
        <ConsumerStatusTopicsTable data={tableRowsData} formData={formData} handleInputChange={handleInputChange} />
      )}
    </>
  );
};

ConsumerStatusTopics.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default ConsumerStatusTopics;
