import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PInstitutionalApi from '../../../../hooks/useP2PInstitutionalApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildInstitutionalArticlesData from './InstitutionalStatusArticles.utils';
import InstitutionalStatusArticlesTable from './InstitutionalStatusArticlesTable/InstitutionalStatusArticlesTable';

const InstitutionalStatusArticles = ({ formData }) => {
  const {
    getInstitutionalArticles,
    institutionalArticlesData,
    errorGettingInstitutionalArticles,
    gettingInstitutionalArticles,
  } = useP2PInstitutionalApi();
  const tableRowsData = institutionalArticlesData
    ? buildInstitutionalArticlesData(institutionalArticlesData?.articles)
    : null;
  const noInstitutionalArticlesFoundMessage = <p className="mt-3">There are no articles.</p>;

  const getUpdatedP2P = useCallback(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getInstitutionalArticles({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${result.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.pending, getInstitutionalArticles]);

  useEffect(() => {
    getUpdatedP2P();

    const interval = setInterval(() => {
      getUpdatedP2P();
    }, 5000);

    return () => clearInterval(interval);
  }, [getUpdatedP2P]);

  return (
    <>
      <Helmet>
        <title>AM: Institutional Status: Articles</title>
      </Helmet>

      {errorGettingInstitutionalArticles?.status === 404 && noInstitutionalArticlesFoundMessage}
      {errorGettingInstitutionalArticles?.status !== 404 && errorGettingInstitutionalArticles && (
        <p className="mt-3">There was an issue loading the institutional articles.</p>
      )}
      {(gettingInstitutionalArticles || !institutionalArticlesData) && !errorGettingInstitutionalArticles && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingInstitutionalArticles && !errorGettingInstitutionalArticles && (
        <InstitutionalStatusArticlesTable data={tableRowsData} />
      )}
    </>
  );
};

InstitutionalStatusArticles.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default InstitutionalStatusArticles;
